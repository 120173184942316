const ROLE_ADMIN = 'ROLE_ADMIN';

const CURRENCIES = {
    EOS: {title: 'EOS', url: 'https://eosflare.io/account/', color: 'red'},
    TRX: {title: 'TRX', url: 'https://tronscan.org/#/address/', color: 'blue'},
    ETH: {title: 'ETH', url: 'https://etherscan.io/address/', color: 'green'},
    MATIC: {title: 'MATIC', url: 'https://beta-mainnet.explorer.matic.network/address/', color: '#2693F9'},
    OXG: {title: 'OXG', url: 'https://eosflare.io/account/', color: '#CC7C1F'},
    USD: {title: 'USD', url: 'https://en.wikipedia.org/wiki/Dollar', color: '#09B32B'},
    GAS: {title: 'GAS', url: 'https://neoscan.io/address/', color: 'purple'}
};

const PAID_MODES = {
    1: {title: 'ALL'},
    2: {title: 'Reward paid'},
    3: {title: 'Reward not paid'},
    4: {title: 'Reward not paid and wait'}
};

export {
    ROLE_ADMIN,
    PAID_MODES,
    CURRENCIES
};
