import axios from 'axios';

import {message} from 'antd';
import AuthHelperMethods from "../auth/AuthHelperMethods";

const successHandler = (response) => {
    return response
};

const errorHandler = (error) => {
    const text = error.data === undefined ? error : error.data.message;
    message.error(text);
    return Promise.reject({...error})
};

const addAuthHeader = (config) => {
    const authHelperMethods = new AuthHelperMethods();
    if (!authHelperMethods.loggedIn()) {
        return config;
    }
    let token = authHelperMethods.getToken();
    config.headers = {...config.headers, 'Authorization': `Bearer ${token}`};
    return config;
};

const axiosInstance = axios.create({});

axiosInstance.interceptors.request.use(
    config => addAuthHeader(config)
);

axiosInstance.interceptors.response.use(
    response => successHandler(response),
    error => errorHandler(error.response)
);

export default axiosInstance;
