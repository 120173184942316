import React, {Component, Fragment} from 'react';
import {Button, Card, Form, Input, InputNumber, message} from 'antd';
import EditableTags from '../../components/editable-tags';
import Api from '../../services/Api'
import withLoading from '../../services/Loading'
import AccountFormTable from './table/AccountTable';

class ReferrerDetail extends Component {

    state = {
        title: 'New referrer',
        buttonTitle: 'Save',
        updateEnable: true,
        data: {aliases: []}
        ,
    };

    componentDidMount() {
        const id = this.props.match.params.id;
        if (typeof id !== 'undefined') {
            Api.get(`/api/referrer/${id}`)
                .then(response => {
                    this.setState({
                        title: 'Edit referrer',
                        buttonTitle: 'Update',
                        data: response.data
                    });
                });
        }
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                values.aliases = this.state.data.aliases;
                values.accounts = this.state.data.accounts;
                console.log('values', values);
                if (this.state.data.id) {
                    withLoading(this, Api.put(`/api/referrer/${this.state.data.id}`, values))
                        .then(() => {
                            message.success('Referrer updated.');
                            this.props.history.push('/referrer');
                        });
                } else {
                    withLoading(this, Api.post(`/api/referrer`, values))
                        .then(() => {
                            message.success('Referrer added.');
                            this.props.history.push('/referrer');
                        });
                }
            }
        });
    };

    handleAliasesChange = (aliases) => {
        console.log('handleAliasesChange', aliases);
        this.setState({data: {...this.state.data, aliases: aliases}});
    };

    handleAccountsChange = (accounts, enabledUpdating = true) => {
        this.setState({
            data: {...this.state.data, accounts: accounts},
            updateEnable: enabledUpdating
        });
    };

    render() {
        const {getFieldDecorator} = this.props.form;
        const formItemLayout = {
            labelCol: {
                xs: {span: 24},
                sm: {span: 3},
            },
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 12},
            }
        };
        return (<Fragment>
            <Card title={this.state.title}>
                <Form {...formItemLayout} onSubmit={this.handleSubmit}>
                    <Form.Item label="Name">
                        {getFieldDecorator("name", {
                            initialValue: this.state.data.name,
                            rules: [{
                                required: true, message: "Please input referrer name!",
                            }],
                        })(
                            <Input/>
                        )}
                    </Form.Item>
                    <Form.Item label="Share">
                        {getFieldDecorator("share", {initialValue: this.state.data.share || 0}, {
                            rules: [{
                                required: true, message: "Please input referrer share!",
                            }],
                        })(
                            <InputNumber min={0} max={100}/>
                        )}
                    </Form.Item>
                    <Form.Item label="Aliases">
                        <EditableTags onChange={this.handleAliasesChange} initialValue={this.state.data.aliases}/>
                    </Form.Item>
                    <Form.Item label="Blockchain accounts:">
                        <div className="bc-acc-block">
                            <AccountFormTable
                                size="small" columns={this.columns}
                                accounts={this.state.data.accounts}
                                onChange={this.handleAccountsChange}
                            />
                        </div>
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit"
                                disabled={!this.state.updateEnable}
                                loading={this.state.isLoading}>{this.state.buttonTitle}</Button>
                        <Button href="/referrer" htmlType="button">Cancel</Button>
                    </Form.Item>
                </Form>
            </Card>
        </Fragment>);
    }
}

export default Form.create({name: 'referrerDetail'})(ReferrerDetail);
