import decode from 'jwt-decode';

import Api from '../services/Api';
import {message} from 'antd';

export default class AuthHelperMethods {

    login = (username, password) => {
        return Api.post('/api/auth/login', {
            'username': username,
            'password': password
        }).then(response => {
            message.success('Logged in successfully');
            this.setToken(response.data.token);
        });
    };

    loggedIn = () => {
        const token = this.getToken();
        return token !== null && token !== 'undefined' && !this.isTokenExpired(token);
    };

    isTokenExpired = token => {
        try {
            const decoded = decode(token);
            return decoded.exp < Date.now() / 1000;
        } catch (err) {
            console.log('expired check failed! error ', err);
            return true;
        }
    };

    setToken = idToken => {
        sessionStorage.setItem('id_token', idToken);
    };

    getToken = () => {
        return sessionStorage.getItem('id_token');
    };

    logout = () => {
        console.log('session storage before removing ', sessionStorage);
        sessionStorage.removeItem('id_token');
    };

    getAuthInfo = () => {
        return decode(this.getToken());
    };

    getUserName = () => {
        return this.getAuthInfo().sub;
    };
}
