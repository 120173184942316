import React, {Component} from 'react';
import {Tag, Tooltip} from "antd";

export default class SlicingTag extends Component {

    constructor(props) {
        super();
        this.state = {
            tag: props.tag,
            color: props.color,
            closable: props.closable,
            onClose: props.onClose
        };
    }

    render() {
        const tag = this.state.tag;
        const isLongTag = tag.length > 30;
        const tagElem = (
            <Tag color={this.state.color} key={tag} closable={this.state.closable} onClose={this.state.onClose}>
                {isLongTag ? `${tag.slice(0, 30)}...` : tag}
            </Tag>
        );

        return isLongTag ? (
            <Tooltip title={tag} key={tag}>
                {tagElem}
            </Tooltip>
        ) : (
            tagElem
        );
    }
}
