import React, {Component} from 'react';
import RewardDateRangeFilter from './RewardDateRangeFilter'
import OxTable from '../../components/table';
import InputSearchColumn from '../../components/search';
import {CURRENCIES, PAID_MODES} from '../../constants';
import {getColumnForAdmin} from '../../auth/componentAccessHelpers';
import {Button, Icon, message, Popover, Tooltip} from 'antd';
import fetchProjects from '../../services/Project';
import withLoading from '../../services/Loading';
import Api from '../../services/Api'
import getCurrencyTag from '../../components/tag/CurrecnyTag';

export default class RewardList extends Component {
    constructor() {
        super();
        this.state = {
            rewards: [],
            searchText: '',
            projects: [],
            balance: 0,
            popconfirmForRecord: null
        };
        this.rewards = React.createRef();
        this.dateFilter = React.createRef();
        this.pay = this.pay.bind(this);
        this.hidePaymentConfirmation = this.hidePaymentConfirmation.bind(this);
        this.handleVisibleChange = this.handleVisibleChange.bind(this);
    }

    getColumns() {
        return [{
            title: 'Referrer',
            dataIndex: 'referrerName',
            sorter: true,
            ...new InputSearchColumn('referrerName'),
        }, {
            title: 'Project name',
            dataIndex: 'projectName',
            filters: Object.entries(this.state.projects).map(([, v]) => ({value: v.id, text: v.name})),
        }, {
            title: 'Currency',
            dataIndex: 'currency',
            filters: Object.entries(CURRENCIES).map(([k, v]) => ({value: k, text: v.title})),
            render: getCurrencyTag,
        }, {
            title: 'Amount',
            dataIndex: 'amount',
            sorter: true,
        }, getColumnForAdmin({
            title: 'Reward paid',
            dataIndex: 'rewardPaid',
            filters: Object.entries(PAID_MODES).map(([, v]) => ({value: v.title, text: v.title})),
            filterMultiple: false,
            className: 'reward-paid-column',
            render: (text, record) => {
                if (this.isRewardNotPaidAndWaitChose()) {
                    return this.handleNotices(record);
                } else {
                    return <Tooltip placement='right'
                                    title="To enable payments please choose 'Reward not paid and wait' filter option for this column">
                        <Button type='default' disabled={true} loading={this.state.isLoading}>
                            <Icon
                                className='reward-paid-button'
                                type='question'
                                style={{color: '#91C5F2'}}
                            />
                        </Button>
                    </Tooltip>
                }
            },
        })];
    }

    handleVisibleChange = (visible, record) => {
        if (visible === true) {
            Api.get(`/api/utility/balance/${record.projectId}/${record.currency}`)
                .then(res => {
                    this.setState({
                        balance: res.data.amount,
                        popconfirmForRecord: record
                    });
                });
        }
    };

    hidePaymentConfirmation = () => {
        this.setState({
            popconfirmForRecord: null,
        });
    };

    //TODO extract all this stuff as separate component
    getPaymentConfirmation(record) {
        if (this.state.popconfirmForRecord !== record) {
            return;
        }
        return <div>
            <Icon type="exclamation-circle" theme="twoTone" twoToneColor="orange"/>
            <span> Do you want to pay {record.amount} {record.currency} to "{record.referrerName}"?</span>
            <p className="remaining-balance-text"><em>Remaining balance for 0xServer for now
                is {this.state.balance} {record.currency}</em></p>
            <div className="payment-confirmation-buttons">
                <Button size="small" className="payment-confirmation-button" onClick={this.hidePaymentConfirmation}>
                    Not now
                </Button>
                {this.getConfirmationButton(record, this.state.balance)}
            </div>
        </div>
    }

    getConfirmationButton(record, balance) {
        if (record.amount > balance) {
            return <Tooltip placement="top"
                            title="Not enough money for payment!">
                <Button size="small" className="payment-confirmation-button" type="primary" disabled={true}>
                    Pay
                </Button>
            </Tooltip>;
        } else {
            return <Button size="small" className="payment-confirmation-button" type="primary"
                           onClick={() => this.pay(record)}>
                Pay
            </Button>;
        }
    }

    handleNotices(record) {
        if (record.notice != null) {
            return <Tooltip placement='right' title={record.notice}>
                <Button type='default' disabled={true} loading={this.state.isLoading}>
                    <Icon
                        className='reward-paid-button'
                        type='lock'
                        theme="twoTone"
                        twoToneColor='#91C5F2'/>
                </Button>
            </Tooltip>
        } else {
            return <Tooltip placement='right' title={`Make payment for ${record.referrerName}`}>
                <Popover
                    content={this.getPaymentConfirmation(record)}
                    trigger="click"
                    visible={this.state.popconfirmForRecord === record}
                    onVisibleChange={visible => this.handleVisibleChange(visible, record)}
                >
                    <Button
                        loading={this.state.isLoading}>
                        <Icon
                            className='reward-paid-button'
                            type='export'
                            style={{color: '#0076F5'}}
                        />
                    </Button>
                </Popover>
            </Tooltip>
        }
    }

    isRewardNotPaidAndWaitChose() {
        const internalFilters = this.rewards.current.state.internalFilters;
        return internalFilters !== undefined
            && internalFilters.rewardPaid !== undefined
            && internalFilters.rewardPaid[0] === PAID_MODES[4].title;
    }

    componentDidMount() {
        fetchProjects()
            .then(res => {
                this.setState({projects: res.data})
            });
    }

    applyFilter = (data) => {
        this.rewards.current.refreshWithExternalFilters({
            since: data.since,
            till: data.till,
        });
    };

    pay = (data) => {
        const extraParams = this.rewards.current.state.extraParams;
        const values = {
            since: extraParams === undefined ? null : extraParams.since,
            till: extraParams === undefined ? null : extraParams.till,
            ...data
        };

        withLoading(this, Api.post(`/api/reward`, values))
            .then(res => {
                message.success(`Payment for ${res.data} purchases was successful.`);
                this.dateFilter.current.applyFilters();
            });
    };

    render() {
        return (
            <OxTable card="Rewards" apiUrl="/api/reward" ref={this.rewards} columns={this.getColumns()}
                     extraParams={this.state}>
                <RewardDateRangeFilter ref={this.dateFilter} applyFilter={this.applyFilter}/>
            </OxTable>
        );
    }
}
