import React, {Component} from 'react';

import {Button, Card, Form, Icon, Input} from 'antd';
import AuthHelperMethods from '../../auth/AuthHelperMethods'
import withLoading from '../../services/Loading';
import './login.css';

class LoginForm extends Component {
    auth = new AuthHelperMethods();
    state = {};

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                console.log(values);
                withLoading(this, this.auth.login(values.username, values.password))
                    .then(() => {
                        this.props.history.replace('/referrer')
                    });
            }
        });
    };

    componentWillMount() {
        if (this.auth.loggedIn())
            this.props.history.replace('/referrer');
    }

    render() {
        const {getFieldDecorator} = this.props.form;
        return (<Card title={'Login'} className='login-card' >
                <Form onSubmit={this.handleSubmit} className='login-form'>
                    <Form.Item hasFeedback>
                        {getFieldDecorator('username', {
                            rules: [{required: true, message: 'Please input username!'}],
                        })(
                            <Input
                                prefix={<Icon type='user' style={{color: 'rgba(0,0,0,.25)'}}/>}
                                placeholder='Username'
                            />,
                        )}
                    </Form.Item>
                    <Form.Item hasFeedback>
                        {getFieldDecorator('password', {
                            rules: [{required: true, message: 'Please input password!'}],
                        })(
                            <Input
                                prefix={<Icon type='lock' style={{color: 'rgba(0,0,0,.25)'}}/>}
                                type='password'
                                placeholder='Password'
                            />,
                        )}
                    </Form.Item>
                    <Form.Item>
                        <div>
                            {getFieldDecorator('remember', {
                                valuePropName: 'checked',
                                initialValue: true,
                            })(<Button onSubmit={this.handleSubmit}
                                       type='primary'
                                       loading={this.state.isLoading}
                                       htmlType='submit'
                                       className='login-form-button'>
                                Log in
                            </Button>)}
                        </div>
                    </Form.Item>
                </Form>
            </Card>
        );
    }
}

export default Form.create({name: 'Login'})(LoginForm);
