import React, {Component} from 'react';

import {Button, Card, Popconfirm, Table} from 'antd';
import Api from '../../services/Api'
import {AdminComponent, getColumnForAdmin} from '../../auth/componentAccessHelpers';

export default class ProjectList extends Component {
    constructor() {
        super();

        this.columns = [{
            title: 'Id',
            dataIndex: 'id',
        }, {
            title: 'Name',
            dataIndex: 'name',
        }, {
            title: 'Ip',
            dataIndex: 'ip',
        }, {
            title: '0xServer url',
            dataIndex: 'oxserverUrl',
        }, {
            title: 'Host name',
            dataIndex: 'hostName',
        }, getColumnForAdmin(
            {
                title: 'Action',
                dataSource: 'action',
                render: (text, record) => (
                    <span className="table-row-actions">
          <Button type="danger"
                  size="small"
                  href={`/project/${record.id}`}
                  icon='edit'>
          </Button>
          <Popconfirm title="Are you sure delete this project?"
                      onConfirm={() => this.delete(record.id)}
                      okText="Yes"
                      cancelText="No">
              <Button type="danger" size="small" icon='delete'/>
          </Popconfirm>
    </span>
                )
            })
        ];
        this.state = {projects: []};
    }

    componentDidMount() {
        this.refresh();
    }

    delete(id) {
        Api.delete(`/api/project/${id}`)
            .then(() => this.refresh());
    }

    refresh = () => {
        Api.get(`/api/project/all`)
            .then(res => {
                return res;
            })
            .then(res => this.setState({projects: res.data}));
    };

    render() {
        return (
            <Card title="Projects">
                <div className="table-top-actions">
                    <Button icon="sync" onClick={this.refresh}/>
                    <AdminComponent>
                        <Button
                            type="primary"
                            htmlType="button"
                            href="/project/new">
                            Add
                        </Button>
                    </AdminComponent>
                </div>
                <Table columns={this.columns} dataSource={this.state.projects} rowKey={record => record.id}/>
            </Card>
        );
    }
}
