import React, {Component} from 'react';
import {Button, Card, Statistic, Table} from 'antd';
import Api from '../../services/Api';
import * as QS from 'qs';
import './table.css';


export default class OxTable extends Component {
    constructor() {
        super();
        this.state = {
            data: [],
            pagination: {},
            internalFilters: {},
            loading: false,
        };
    }

    componentDidMount() {
        this.refreshWithInternalFilters();
    }

    refreshWithExternalFilters = (externalFilters = {}) => {
        this.setState({
            extraParams: externalFilters,
            pagination: {}
        });
        const resultParams = {...this.state.internalFilters, ...externalFilters};
        this.refresh(resultParams);
    };

    refreshWithInternalFilters = (tableFilters = {}) => {
        this.setState({
            internalFilters: tableFilters.internalFilters
        });
        const resultParams = {...this.state.extraParams, ...tableFilters.internalFilters, ...tableFilters};
        this.refresh(resultParams);
    };

    refresh(resultParams) {
        this.setState({
            loading: true
        });

        Api.get(this.props.apiUrl, {
            params: resultParams,

            paramsSerializer: (params) => {
                return QS.stringify(params, {arrayFormat: 'repeat'})
            }
        }).then(res => {
            const pagination = {...this.state.pagination};
            pagination.total = res.data.total;
            this.setState({
                loading: false,
                data: res.data.results,
                pagination
            });
            if (this.props.currentFilters !== undefined) {
                this.props.currentFilters(resultParams);
            }
        });
    }

    handleTableChange = (pagination, filters, sorter) => {
        const pager = {...this.state.pagination};
        pager.current = pagination.current;
        this.setState({
            pagination: pager,
        });
        this.refreshWithInternalFilters({
            offset: pagination.pageSize * (pagination.current - 1),
            limit: pagination.pageSize,
            sortField: sorter.field,
            sortOrder: sorter.order && sorter.order.toUpperCase(),
            internalFilters: filters,
        });
    };

    getFooter = () => {
        return <div className='footer'>
            <span>Total: </span>
            <Statistic
                value={this.state.pagination.total}
            />
        </div>
    };

    render() {
        const table = (
            <Table columns={this.props.columns}
                   dataSource={this.state.data}
                   loading={this.state.loading}
                   pagination={this.state.pagination}
                   onChange={this.handleTableChange}
                   rowKey={record => record.id}
                   footer={this.getFooter}/>
        );
        let topActions;
        if (this.props.withSync) {
            topActions = (
                <div className="table-top-actions">
                    <Button icon="sync" onClick={() => {
                        if (this.props.onSync === undefined) {
                            this.refreshWithInternalFilters()
                        } else {
                            this.props.onSync();
                        }
                    }
                    }/>
                    {this.props.children}
                </div>
            );
        } else {
            topActions = (
                <div className="table-top-actions">
                    {this.props.children}
                </div>)
        }
        if (this.props.card) {
            return (
                <Card className='card-table' title={this.props.card}>
                    {topActions}
                    {table}
                </Card>
            );
        } else {
            return table;
        }
    }
}
