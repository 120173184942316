import React, {Component} from 'react';
import {Button, message, Modal, Popconfirm, Tag, Tooltip} from 'antd';
import Api from "../../services/Api";
import OxTable from '../../components/table';
import InputSearchColumn from "../../components/search";

export default class DeleteInFavor extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            onDelete: props.onDelete
        };
        this.columns = [{
            title: 'Id',
            dataIndex: 'id',
            sorter: true
        }, {
            title: 'Name',
            dataIndex: 'name',
            sorter: true,
            ...new InputSearchColumn('name')
        }, {
            title: 'Aliases',
            dataIndex: 'aliases',
            render: aliases => (
                <span>
      {aliases.map(alias => <Tag color='green' key={alias}>{alias}</Tag>)}
    </span>
            ),
            ...new InputSearchColumn('aliases')
        }, {
            title: 'Choose',
            key: 'action',
            render: (text, record) => (
                <span className="actions">
        <Popconfirm title="Are you sure delete referrer? Corresponding purchases will be linked to successor."
                    onConfirm={() => this.deleteInFavor(record)} okText="Yes"
                    cancelText="No">
            <Tooltip placement="top" title="Choose successor">
                <Button type="danger" size="small" icon='user'/>
            </Tooltip>
        </Popconfirm>
    </span>
            ),
        }];
    }

    componentWillReceiveProps(props) {
        if (props.referrer) {
            console.log('Showing delete in favor for', props.referrer);
            this.setState({
                visible: true,
                referrer: props.referrer
            });
        }
    }

    deleteInFavor(successor) {
        Api.delete(`/api/referrer/${this.state.referrer.id}/${successor.id}`)
            .then(() => {
                message.success(`Referrer '${this.state.referrer.name}' deleted. Purchases linked to '${successor.name}'.`);
                this.setState({
                    visible: false,
                });
                this.state.onDelete();
            });
    };

    handleCancel = e => {
        this.setState({
            visible: false,
        });
    };

    render() {
        if (this.props.referrer) {
            return (
                <Modal
                    title={`Delete '${this.state.referrer && this.state.referrer.name}' - choose a successor for purchases`}
                    visible={this.state.visible}
                    onCancel={this.handleCancel}
                    footer={null}
                    width={650}
                >

                    <OxTable apiUrl={`/api/referrer?excludeId=${this.props.referrer.id}`} columns={this.columns}/>
                </Modal>
            );
        } else {
            return null;
        }
    }
}
