import {Button, Icon, Input} from 'antd';

import React from 'react';

import './index.css'

export default class InputSearchColumn {
    constructor(dataIndex) {
        this.dataIndex = dataIndex;
        this.searchInput = null;
    }

    filterDropdown = ({setSelectedKeys, selectedKeys, confirm, clearFilters}) => (
        <div className='search-column-main'>
            <Input
                ref={node => {
                    this.searchInput = node;
                }}
                placeholder={`Search ${this.dataIndex}`}
                value={selectedKeys}
                onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                onPressEnter={() => this.onSearch(selectedKeys, confirm)}
                className='search-column-input'
            />
            <Button
                type="primary"
                onClick={() => this.onSearch(selectedKeys, confirm)}
                icon="search"
                size="small"
                className='search-button'
            >
                Search
            </Button>
            <Button onClick={() => this.onReset(clearFilters)} size="small" className='reset-button'>
                Reset
            </Button>
        </div>
    );

    filterIcon = (filtered) => (
        <Icon type="search" style={{color: filtered ? '#0636ff' : undefined}}/>
    );

    onFilterDropdownVisibleChange = (visible) => {
        if (visible) {
            setTimeout(() => this.searchInput.select());
        }
    };

    onSearch = (selectedKeys, confirm) => {
        confirm();
    };

    onReset = clearFilters => {
        clearFilters();
    }
}
