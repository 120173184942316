import React, {Component} from 'react';
import {DatePicker} from 'antd';
import './rewards.css';
import moment from 'moment';

const {RangePicker} = DatePicker;

export default class RewardDateRangeFilter extends Component {
    constructor() {
        super();
        this.state = {
            since: null,
            till: null
        }
    }

    chooseDates = choseDates => {
        this.setState({
            since: this.toDate(choseDates[0]),
            till: this.toDate(choseDates[1])
        });

        if (choseDates.length === 0) {
            this.clearFilters();
        }
    };

    clearFilters = () => {
        let newState = {
            since: null,
            till: null
        };
        this.props.applyFilter(newState);
        this.setState(newState);
    };

    toDate = (param) => {
        if (param) {
            return param.toDate()
        }
    };

    toMoment = (date) => {
        return date == null ? null : moment(date);
    };

    applyFilters = () => {
        this.props.applyFilter(this.state)
    };

    render() {
        return <div>
            <RangePicker
                showTime={{
                    hideDisabledOptions: true,
                    defaultValue: [moment('00:00:00', 'HH:mm:ss'), moment('23:59:59', 'HH:mm:ss')],
                }}
                value={[this.toMoment(this.state.since), this.toMoment(this.state.till)]}
                disabledDate={current => current >= moment().endOf('day')}
                format='YYYY-MM-DD HH:mm:ss'
                onChange={this.chooseDates}
                onOk={this.applyFilters}
            />
        </div>
    }
}