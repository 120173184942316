import React, {Component} from 'react';

import {Form, Input, Select} from 'antd';
import {CURRENCIES} from '../../../constants';
import AccountContext from '../../../context/AccountContext';


const {Option} = Select;

export default class AccountCell extends Component {

    getInput = (dataIndex, selectedCurrencies) => {
        if (dataIndex === 'currency') {
            return <Select
                placeholder='select currency'
                optionLabelProp='label'
            >
                {Object.entries(CURRENCIES)
                    .filter(value => this.isCurrencyAlreadyAdded(selectedCurrencies, value))
                    .map(([key, value]) => <Option key={key} value={key} label={value.title}>{value.title}</Option>)}
            </Select>
        }
        return <Input/>;
    };

    isCurrencyAlreadyAdded(selectedCurrencies, value) {
        if (selectedCurrencies !== undefined) {
            return !selectedCurrencies.includes(value[[0]])
        }
    }

    renderCell = ({getFieldDecorator}) => {
        const {
            editing,
            dataIndex,
            record,
            children,
            selectedCurrencies,
            ...restProps
        } = this.props;
        return (
            <td {...restProps}>
                {editing ? (
                    <Form.Item>
                        {getFieldDecorator(dataIndex, {
                            rules: [
                                {
                                    required: true,
                                    message: "Please input!",
                                },
                            ],
                            initialValue: record[dataIndex],
                        })(this.getInput(dataIndex, selectedCurrencies))}
                    </Form.Item>
                ) : (
                    children
                )}
            </td>
        );
    };

    render() {
        return <AccountContext.Consumer>{this.renderCell}</AccountContext.Consumer>;
    }
}
