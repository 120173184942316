import React, {Component} from 'react';
import {Button, Card, Form, Input, message, Tooltip} from 'antd';
import Api from "../../services/Api";
import withLoading from '../../services/Loading'
import './detail.css'

const IP_REGEXP = new RegExp("^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$");

class ProjectDetail extends Component {
    state = {
        title: 'New project',
        buttonTitle: 'Save',
        data: {},
    };

    componentDidMount() {
        const id = this.props.match.params.id;
        if (typeof id !== 'undefined') {
            Api.get(`/api/project/` + id)
                .then(response =>
                    this.setState({
                        id: id,
                        title: 'Edit project',
                        buttonTitle: 'Update',
                        data: response.data
                    }));
        }
        this.updateHostNameValue = this.updateHostNameValue.bind(this);
        this.resolveIp = this.resolveIp.bind(this);
        this.checkUrl = this.checkUrl.bind(this);
        this.updateOxServerUrlValue = this.updateOxServerUrlValue.bind(this);
    }

    saveNewProject(values) {
        withLoading(this, Api.post(`/api/project`, values))
            .then(() => {
                message.success('Project added.');
                this.props.history.push('/project');
            });
    };

    updateProject(values) {
        withLoading(this, Api.put(`/api/project/${this.state.data.id}`, values))
            .then(() => {
                message.success('Project updated.');
                this.props.history.push('/project');
            });
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.state.id ? this.updateProject(values) : this.saveNewProject(values);
            }
        });
    };

    resolveIp() {
        withLoading(this, Api.get(`/api/utility/ip/resolve/${this.state.data.hostName}`))
            .then((response) => {
                message.success('Ip autodetected, please verify it.');
                this.setState(prevState =>
                    ({
                        ...prevState,
                        data: {
                            ...prevState.data,
                            ip: response.data
                        }
                    }));

            })
    }

    checkUrl() {
        withLoading(this, Api.get(`${this.state.data.oxServerUrl}/api/actuator/health`))
            .then((response) => {
                if (response.data !== undefined && response.data.status === "UP") {
                    message.success('Server available');
                } else {
                    message.error(`Server available. But doesn't work for now. Status is ${response.data.status}`)
                }
            })
            .catch(any => {
                message.error('Server unavailable.')
            })
    }

    updateOxServerUrlValue = (value) => {
        const newOxServerUrl = value.target.value;
        this.setState(prevState =>
            ({
                ...prevState,
                data: {
                    ...prevState.data,
                    oxServerUrl: newOxServerUrl
                }
            }));
    };

    updateHostNameValue = (value) => {
        const newHostName = value.target.value;
        this.setState(prevState =>
            ({
                ...prevState,
                data: {
                    ...prevState.data,
                    hostName: newHostName
                }
            }));
    };

    render() {
        const {getFieldDecorator} = this.props.form;
        const formItemLayout = {
            labelCol: {
                xs: {span: 30},
                sm: {span: 4},
            },
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 10},
            },
        };

        const tailFormItemLayout = {
            wrapperCol: {
                xs: {
                    span: 24,
                    offset: 0,
                },
                sm: {
                    span: 16,
                    offset: 2,
                },
            },
        };

        return (<Card title={this.state.title}>
            <Form {...formItemLayout} onSubmit={this.handleSubmit}>
                <Form.Item label="Name"
                           hasFeedback>
                    {getFieldDecorator('name', {
                        initialValue: this.state.data.name,
                        rules: [{
                            required: true,
                            message: 'Please input project name!',
                        }],
                    })(
                        <Input/>
                    )}
                </Form.Item>
                <Form.Item label="Host name">
                    <div className='field-in-project'>
                        {getFieldDecorator('hostName', {
                            initialValue: this.state.data.hostName,
                            rules: [{
                                required: true, message: 'Please input hostName!',
                            }],
                        })(<Input onChange={this.updateHostNameValue}/>)}
                        <Tooltip placement='top' title='Get ip of specified host'>
                            <Button
                                htmlType='button'
                                type='primary'
                                onClick={this.resolveIp}
                                icon='link'
                                loading={this.state.isLoading}
                                title='Get ip'>
                            </Button>
                        </Tooltip>
                    </div>
                </Form.Item>
                <Form.Item label="Ip"
                           hasFeedback
                           help="Ip should be in [0-255].[0-255].[0-255].[0-255] format">
                    {getFieldDecorator('ip', {
                        initialValue: this.state.data.ip,
                        rules: [{
                            required: true,
                            message: 'Please input ip in correct format!',
                            pattern: IP_REGEXP
                        }],

                    })(
                        <Input/>
                    )}
                </Form.Item>
                <Form.Item label="0xServer url"
                           help="It should be valid url">
                    <div className='field-in-project'>
                        {getFieldDecorator('oxServerUrl', {
                            initialValue: this.state.data.oxserverUrl,
                            rules: [{
                                message: 'Please input url in correct format!',
                                type: 'url',
                            }],
                        })(
                            <Input onChange={this.updateOxServerUrlValue}/>
                        )}
                        <Tooltip placement='top' title='Check availability of server'>
                            <Button
                                htmlType='button'
                                type='primary'
                                icon='monitor'
                                onClick={this.checkUrl}
                                loading={this.state.isLoading}
                                title='Check server'>
                            </Button>
                        </Tooltip>
                    </div>
                </Form.Item>
                <Form.Item {...tailFormItemLayout}>
                    <Button type="primary" htmlType="submit"
                            loading={this.state.isLoading}>{this.state.buttonTitle}</Button>
                    <Button href="/project" htmlType="button" style={{marginLeft: 8}}>Cancel</Button>
                </Form.Item>
            </Form>
        </Card>);
    }
}

export default Form.create({name: 'ProjectDetail'})(ProjectDetail);

