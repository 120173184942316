import React, {Component} from 'react';
import {Button, Icon, message, Select} from 'antd';
import ReactCountryFlag from 'react-country-flag';
import OxTable from '../../components/table';
import fetchProjects from '../../services/Project';
import InputSearchColumn from '../../components/search';
import {CURRENCIES} from '../../constants';
import Api from '../../services/Api';
import RewardDateRangeFilter from '../reward/RewardDateRangeFilter';
import getCurrencyTag from '../../components/tag/CurrecnyTag';
import './purchase.css';
import * as QS from 'qs';

const FileDownload = require('js-file-download');

export default class PurchaseList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            projects: [],
            types: [],
            isExporting: false
        };
        this.table = React.createRef();
    }

    getColumns() {
        return [
            {
                title: 'Referrer name',
                dataIndex: 'referrerName',
                ...new InputSearchColumn('referrerName')
            },
            {
                title: 'Buyer account',
                dataIndex: 'buyerAccount',
            },
            {
                title: 'Buyer country',
                dataIndex: 'buyerCountry',
                render: country => {
                    if (country == null) {
                        return <span/>
                    }
                    if (country !== 'undefined') {
                        return <ReactCountryFlag code={country} svg={true}
                                                 styleProps={{
                                                     fontSize: '2.5em',
                                                     border: '1px solid gray',
                                                     backgroundSize: '1.3333333333em 1em'
                                                 }}/>
                    } else {
                        return <span id="unknown-country-flag"/>
                    }
                }
            },

            {
                title: 'Beneficiary account',
                dataIndex: 'beneficiaryAccount',
            },
            {
                title: 'Seller account',
                dataIndex: 'sellerAccount',
            },
            {
                title: 'Type',
                dataIndex: 'type',
                filters: Object.entries(this.state.types).map(([k, v]) => ({value: v, text: v})),
            },
            {
                title: 'Transaction Id ',
                dataIndex: 'transactionId',
                ...new InputSearchColumn('transactionId')
            },
            {
                title: 'Currency',
                dataIndex: 'currency',
                filters: Object.entries(CURRENCIES).map(([k, v]) => ({value: k, text: v.title})),
                render: getCurrencyTag,
            },
            {
                title: 'Date of bought',
                dataIndex: 'boughtDate',
                sorter: true,
                filterDropdown: this.filterDropdown,
            },
            {
                title: 'Project name',
                dataIndex: 'projectName',
            },
            {
                title: 'Was reward paid',
                dataIndex: 'referrerRewardPaid',
                filters: [{text: 'Yes', value: 'true'}, {text: 'No', value: 'false'}],
                filterMultiple: false,
                render: val => {
                    return val ?
                        <Icon type='smile' className='paid' theme='twoTone' twoToneColor='#52c41a'/> :
                        <Icon type='close-circle' className='paid' theme='twoTone' twoToneColor='#f2546e'/>;
                }
            },
            {
                title: 'Income euro',
                dataIndex: 'incomeEur',
            },
            {
                title: 'Income usd',
                dataIndex: 'incomeUsd',
                sorter: true,
            },
            {
                title: 'Income',
                dataIndex: 'incomeCrypto',
            },
            {
                title: 'Revenue usd',
                dataIndex: 'revenueUsd',
                sorter: true,
            },
            {
                title: 'Revenue euro',
                dataIndex: 'revenueEur',
            },
            {
                title: 'Revenue',
                dataIndex: 'revenueCrypto',
            }
        ]
    }

    componentDidMount() {
        fetchProjects()
            .then(res => {
                this.setState({
                    projects: res.data,
                })
            });
    }

    getPurchasesForProject = projectId => {
        Api.get(`/api/purchase/type/${projectId}`)
            .then(res => {
                this.setState({types: res.data})
            });

        this.setState({
            currentProjectId: projectId
        });

        this.table.current.refreshWithExternalFilters({
            projectId: projectId,
        })
    };

    applyFilter = (data) => {
        this.table.current.refreshWithInternalFilters({
            since: data.since,
            till: data.till,
        });
    };

    filterDropdown = ({setSelectedKeys, confirm}) => {
        return (
            <div className="purchase-date-of-bought-filter">
                <RewardDateRangeFilter applyFilter={(data) => {
                    setSelectedKeys([data.since, data.till]);
                    confirm();
                }}/>
            </div>)
    };

    eachProject(project) {
        const Option = Select.Option;
        return <Option key={project.id}>{project.name}</Option>;
    }

    exportPurchases() {
        this.setState({
            isExporting: true
        });
        Api.get('/api/purchase/export', {
            params: this.state.currentFilters,

            paramsSerializer: (params) => {
                return QS.stringify(params, {arrayFormat: 'repeat'})
            }
        }).then(response => {
            const fileName = `purchases-${(this.getFormattedDate())}.csv`;
            FileDownload(response.data, fileName);
            message.success(`Purchases were exported into ${fileName} successfully!`);
            this.setState({
                isExporting: false
            })
        })
    }

    setFilters = (data = {}) => {
        this.setState({
            currentFilters: data
        });
    };

    getFormattedDate() {
        return new Date().toLocaleDateString().replace(/\//g, "-");
    }

    isReadyForExport() {
        if (this.table.current !== null) {
            return this.table.current.state.data.length > 0
        }
    }

    syncPurchases() {
        this.table.current.refreshWithInternalFilters(this.state.currentFilters)
    }

    render() {
        return (<OxTable card='Purchases' apiUrl='/api/purchase/' ref={this.table}
                         columns={this.getColumns()} withSync
                         currentFilters={this.setFilters}
                         onSync={() => this.state.currentProjectId !== undefined
                             ? this.syncPurchases()
                             : {}
                         }>
                    <Select
                        showSearch
                        className="choose-project"
                        placeholder="Select a project"
                        optionFilterProp="children"
                        onChange={this.getPurchasesForProject}
                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    >
                        {this.state.projects.map(this.eachProject)}
                    </Select>
                <Button icon="file"
                        loading={this.state.isExporting}
                        onClick={() => this.exportPurchases()}
                        disabled={!this.isReadyForExport()}
                        type="primary"
                        ghost
                        id="export-button">
                    Export
                </Button>
            </OxTable>
        );
    }
}
