import React, {Component} from 'react';
import NavMenu from './components/nav';
import Routes from './routes';
import {BrowserRouter} from 'react-router-dom';
import {Col, Row} from "antd";

class App extends Component {
    render() {
        return (
            <div className="App">
                <BrowserRouter>
                    <NavMenu/>
                    <br/>
                    <Row type="flex" justify="center" align="middle">
                        <Col
                            xs={{span: 23}}
                            sm={{span: 23}}
                            md={{span: 21}}
                            lg={{span: 21}}
                            xl={{span: 19}}
                        >
                            <Routes/>
                        </Col>
                    </Row>
                </BrowserRouter>
            </div>
        );
    }
}

export default App;
