import React, {Component} from 'react';

import {Icon, Menu} from 'antd';
import {NavLink, withRouter} from 'react-router-dom';
import AuthHelperMethods from '../../auth/AuthHelperMethods';

class NavMenu extends Component {
    auth = new AuthHelperMethods();

    logout = () => {
        console.log('log out');
        this.auth.logout();
        this.props.history.replace('/login');
    };

    render() {
        const {location} = this.props;
        if (!this.auth.loggedIn()) {
            return <Menu selectable={false}>
                <Menu.Item key="/login">
                    <Icon type='login'/> Login
                </Menu.Item>
            </Menu>
        }
        return (
            <Menu selectedKeys={[location.pathname]}
                  mode="horizontal">
                <Menu.Item key="/referrer">
                    <NavLink to="/referrer">
                        <Icon type="solution"/>Referrers
                    </NavLink>
                </Menu.Item>
                <Menu.Item key="/project">
                    <NavLink to="/project">
                        <Icon type="shop"/>Projects
                    </NavLink>
                </Menu.Item>
                <Menu.Item key="/reward">
                    <NavLink to="/reward">
                        <Icon type="transaction"/>Rewards
                    </NavLink>
                </Menu.Item>
                <Menu.Item key="/purchase">
                    <NavLink to="/purchase">
                        <Icon type="money-collect"/>Purchases
                    </NavLink>
                </Menu.Item>
                <Menu.Item key="/logout" style={{float: 'right'}} onClick={this.logout}>
                    <NavLink to='/'>
                        <Icon type='logout'/>
                        Logout
                    </NavLink>
                </Menu.Item>
                <Menu.Item key="/userName" style={{float: 'right'}} disabled={true}>
                    <NavLink to='/'>
                        <Icon type="user"/>
                        {this.auth.getUserName()}
                    </NavLink>
                </Menu.Item>
            </Menu>
        );
    }
}

export default withRouter(NavMenu);
