import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import ReferrerList from './views/referrer/List';
import ReferrerDetail from './views/referrer/Detail'
import Purchase from './views/purchase/List';
import ProjectList from './views/project/List';
import ProjectDetail from './views/project/Detail'
import RewardList from './views/reward/List'
import LoginForm from './views/login/Login'
import withAuth from "./auth/withAuth";
import {ROLE_ADMIN} from "./constants.js";

const routes = () => (
    <Switch>
        <Route exact path="/" render={() => (<Redirect to="/referrer"/>)}/>
        <Route path="/referrer" exact={true} component={withAuth(ReferrerList)}/>
        <Route path="/referrer/new" exact={true} component={withAuth(ReferrerDetail, ROLE_ADMIN)}/>
        <Route path="/referrer/:id" exact={true} component={withAuth(ReferrerDetail, ROLE_ADMIN)}/>
        <Route path="/purchase" exact={true} component={withAuth(Purchase)}/>
        <Route path="/project" exact={true} component={withAuth(ProjectList)}/>
        <Route path="/project/new" exact={true} component={withAuth(ProjectDetail, ROLE_ADMIN)}/>
        <Route path="/project/:id" exact={true} component={withAuth(ProjectDetail, ROLE_ADMIN)}/>
        <Route path="/reward" exact={true} component={withAuth(RewardList)}/>
        <Route path="/login" exact={true} component={LoginForm}/>
    </Switch>
);

export default routes;
