import React, {Component, Fragment} from 'react';
import AuthHelperMethods from './AuthHelperMethods';
import {ROLE_ADMIN} from '../constants';

const Auth = new AuthHelperMethods();

function isUserAdmin() {
    let authInfo = Auth.getAuthInfo();
    return authInfo.roles.includes(ROLE_ADMIN);
}

function getColumnForAdmin(columns) {
    if (isUserAdmin()) {
        return columns;
    } else {
        return {}
    }
}

class AdminComponent extends Component {
    render() {
        if (isUserAdmin()) {
            return <Fragment>
                {this.props.children}
            </Fragment>
        } else {
            return <Fragment/>
        }
    }
}

export {
    getColumnForAdmin,
    AdminComponent
};
