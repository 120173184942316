import React, {Component} from 'react';

import {Button, Icon, message, Popconfirm, Tag, Tooltip} from 'antd';
import DeleteInFavor from './DeleteInFavor';
import SlicingTag from '../../components/slicing-tag';
import Api from '../../services/Api'
import {CURRENCIES} from '../../constants';
import InputSearchColumn from '../../components/search';
import OxTable from '../../components/table';
import {AdminComponent, getColumnForAdmin} from '../../auth/componentAccessHelpers';

export default class ReferrerList extends Component {
    constructor(props) {
        super(props);
        this.columns = [{
            title: 'Id',
            dataIndex: 'id',
            sorter: true
        }, {
            title: 'Name',
            dataIndex: 'name',
            sorter: true,
            ...new InputSearchColumn('name')
        }, {
            title: 'Aliases',
            dataIndex: 'aliases',
            ...new InputSearchColumn('aliases'),
            render: aliases => (
                <div>
                    {
                        aliases.map(alias => <SlicingTag color='green' tag={alias} key={alias}/>)
                    }
                </div>
            )
        }, {
            title: 'Accounts',
            dataIndex: 'accounts',
            filters: Object.entries(CURRENCIES).map(([k, v]) => ({value: k, text: v.title})),
            render: accounts => {
                if (accounts) {
                    return (<span className="tag-list">
                        {accounts.map(account =>
                            <Tag color='blue' key={account.currency}>
                                <a target="_blank" rel="noopener noreferrer"
                                   href={CURRENCIES[account.currency].url + account.accountName}
                                   key={account.currency}>{account.currency}</a>
                            </Tag>)}
                            </span>);
                }
            },
        }, {
            title: 'Share',
            dataIndex: 'share',
            sorter: true
        }, {
            title: 'Total revenue',
            dataIndex: 'totalRevenue',
            sorter: true
        },
            getColumnForAdmin({
                title: 'Action',
                dataSource: 'action',
                render: (text, record) => (
                    <span className="table-row-actions">
        <Tooltip placement="top" title="Edit referrer">
            <Button type="danger"
                    size="small"
                    href={`/referrer/${record.id}`}
                    icon='edit'>
            </Button>
        </Tooltip>
        <Popconfirm title="Are you sure delete this referrer? Corresponding purchases will be unlinked."
                    onConfirm={() => this.delete(record.id)} okText="Yes"
                    cancelText="No">
            <Tooltip placement="top" title="Delete referrer">
                <Button type="danger" size="small" icon='delete'/>
            </Tooltip>
        </Popconfirm>
        <Tooltip placement="top" title="Delete in favor of other referrer">
            <Button type="danger" size="small" onClick={() => this.showDeleteInFavor(record)}
                    className="ant-btn-icon-only">
                <Icon type="delete"/>
                <Icon type="arrow-right"/>
                <Icon type="user"/>
            </Button>
        </Tooltip>
    </span>
                )
            })];
        this.table = React.createRef();
        this.state = {};
    }

    delete(id) {
        Api.delete(`/api/referrer/${id}`)
            .then(() => {
                message.success('Referrer deleted. Purchases unlinked.');
                this.table.current.refreshWithInternalFilters();
            });
    }

    showDeleteInFavor(referrer) {
        this.setState({
            deleteInFavor: referrer
        });
    }

    onDeleteInFavor = () => {
        this.setState({deleteInFavor: undefined});
        this.table.current.refreshWithInternalFilters();
    };

    render() {
        return (
            <OxTable card="Referrers" apiUrl="/api/referrer" ref={this.table} columns={this.columns} withSync>
                <AdminComponent>
                    <Button
                        type="primary"
                        htmlType="button"
                        href="/referrer/new">
                        Add
                    </Button>
                </AdminComponent>
                <DeleteInFavor referrer={this.state.deleteInFavor} onDelete={this.onDeleteInFavor}/>
            </OxTable>
        );
    }
}
