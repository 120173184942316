import React, {Component, Fragment} from 'react';

import {Button, Form, Popconfirm, Table} from 'antd';
import AccountCell from './AccountCell';
import AccountContext from '../../../context/AccountContext';
import {CURRENCIES} from '../../../constants';

import '../account.css';

class AccountTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            editingKey: ''
        };

        this.columns = [
            {
                title: 'Account name',
                dataIndex: 'accountName',
                editable: true
            },
            {
                title: 'Currency',
                dataIndex: 'currency',
                editable: true
            },
            {
                title: 'Action',
                dataIndex: 'action',
                render: (text, record) => {
                    const editable = this.isRowInEditing(record);
                    return this.isAccountsNotEmpty() ? (
                        editable ? this.getEditableActions(record)
                            : this.getBaseActions(record)
                    ) : null
                }
            },
        ];
    }

    isAccountsNotEmpty = () => this.props.accounts.length >= 1;

    isRowInEditing = record => record === this.state.editingKey;

    isEditing = () => this.state.editingKey !== '';

    editRow = (key) => {
        this.setState({editingKey: key});
    };

    hasAllCurrencies = () => {
        return this.props.accounts !== undefined
            && this.props.accounts.length === Object.entries(CURRENCIES).length
    };

    handleAddRow = () => {
        const {accounts} = this.props;
        const newData = {};
        this.setState({
            editingKey: newData
        });
        if (accounts !== undefined) {
            this.props.onChange([...accounts, newData], this.isEditing())
        } else {
            this.props.onChange([newData], this.isEditing())
        }
    };

    handleCancelEditing = (record) => {
        let accounts = [...this.props.accounts];
        if (record.currency === undefined || record.accountName === undefined) {
            accounts =
                accounts.filter(item => item !== record)
        }

        this.setState({
            editingKey: ''
        });
        this.props.onChange(accounts, this.isEditing())
    };

    handleDeleteRow = (record) => {
        const accounts = [...this.props.accounts];

        let accountsForAdding = accounts.filter(item => item !== record);
        this.setState({
            editingKey: ''
        });
        this.props.onChange(accountsForAdding)

    };

    saveRow(form, record) {
        form.validateFields((error, row) => {
            if (error) {
                return;
            }
            const newData = [...this.props.accounts];
            const index = newData.findIndex(item => record === item);

            if (index > -1) {
                const item = newData[index];
                newData.splice(index, 1, {
                    ...item,
                    ...row,
                });
                this.setState({editingKey: ''});
                this.props.onChange(newData, this.isEditing())

            } else {
                newData.push(row);
                this.setState({editingKey: ''});
                this.props.onChange(newData, this.isEditing())

            }
        });
    }

    getEditableActions = (record) => {
        return <Fragment>
            <AccountContext.Consumer>
                {form => (
                    <Button
                        type='danger'
                        size='small'
                        icon='check'
                        onClick={() => this.saveRow(form, record)}
                    />
                )}
            </AccountContext.Consumer>
            <Popconfirm title='Sure to cancel?' onConfirm={() => this.handleCancelEditing(record)}>
                <Button
                    type='danger'
                    size='small'
                    icon='stop'/>
            </Popconfirm>
        </Fragment>
    };

    getBaseActions(record) {
        let isEditing = this.isEditing();
        return <Fragment>
            <Button
                type='danger'
                size='small'
                icon='edit'
                disabled={isEditing}
                onClick={() => this.editRow(record)}/>
            <Popconfirm title='Sure to delete row?'
                        onConfirm={() => this.handleDeleteRow(record)}>
                <Button size='small' type='danger' icon='delete' disabled={isEditing}/>
            </Popconfirm>
        </Fragment>
    }


    render() {
        const components = {
            body: {
                cell: AccountCell
            },
        };

        const columns = this.columns.map(col => {
            if (!col.editable) {
                return col;
            }
            let selectedCurrencies = [];
            if (this.props.accounts !== undefined) {
                selectedCurrencies = this.props.accounts.map(account => account.currency)
            }
            return {
                ...col,
                onCell: record => ({
                    record,
                    dataIndex: col.dataIndex,
                    title: col.title,
                    selectedCurrencies: selectedCurrencies,
                    editing: this.isRowInEditing(record),
                }),
            };
        });

        return (
            <Fragment>
                <AccountContext.Provider value={this.props.form}>
                    <Table
                        size="small"
                        components={components}
                        rowKey={record => record.accountName + record.currency + ""}
                        columns={columns}
                        dataSource={this.props.accounts}
                        bordered
                        pagination={false}
                    />
                    <Button type="primary"
                            size="small"
                            onClick={this.handleAddRow}
                            icon="plus-circle"
                            className="add-row-button"
                            disabled={this.isEditing() || this.hasAllCurrencies()}
                    >
                        Add
                    </Button>
                </AccountContext.Provider>
            </Fragment>
        );
    }
}

export default Form.create()(AccountTable);