export default function withLoading(component, promise) {
    return new Promise((resolve, reject) => {
        component.setState({isLoading: true});
        promise.then(response => {
                component.setState({isLoading: false});
                resolve(response);
            },
            response => {
                component.setState({isLoading: false});
                reject(response);
            });
    });
};
