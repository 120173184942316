import React, {Component} from 'react';
import AuthHelperMethods from './AuthHelperMethods';

export default function withAuth(AuthComponent, rolesForAccess) {
    const Auth = new AuthHelperMethods();


    return class AuthWrapped extends Component {
        state = {
            loaded: false
        };
        rolesForAccessToComponent = rolesForAccess;

        componentDidMount() {
            if (!Auth.loggedIn()) {
                this.props.history.replace('/login');
            } else {
                try {
                    const authInfo = Auth.getAuthInfo();
                    console.log('auth info is: ', authInfo);
                    if (!this.userHasAccess(authInfo)) {
                        console.log("User doesn't have access");
                        this.props.history.replace('/referrer');
                    }
                    this.setState({
                        loaded: true
                    });

                } catch (err) {
                    console.log(err);
                    Auth.logout();
                    this.props.history.replace('/login');
                }
            }
        }

        userHasAccess(authInfo) {
            return (authInfo && this.rolesForAccessToComponent === undefined) ||
                (authInfo && this.rolesForAccessToComponent !== undefined &&
                    authInfo.roles.some(el => this.rolesForAccessToComponent.includes(el)));
        }

        render() {
            if (this.state.loaded) {
                return (
                    <AuthComponent
                        history={this.props.history}
                        match={this.props.match}
                    />
                );
            } else {
                return null;
            }
        }
    };
}
