import {Icon, Input, Tag,} from 'antd';
import SlicingTag from '../slicing-tag';

import React, {Component} from 'react';

export default class EditableTags extends Component {
    constructor(props) {
        super(props);

        this.state = {
            tags: props.initialValue,
            inputVisible: false,
            inputValue: '',
        };
    }

    componentWillReceiveProps(props) {
        console.log('componentWillReceiveProps', props.initialValue, this.state.tags);
        if (this.state.tags.length === 0) {
            this.setState({
                tags: props.initialValue
            });
        }
    }

    handleClose = (removedTag) => {
        const tags = this.state.tags.filter(tag => tag !== removedTag);
        console.log('handleClose', tags);
        this.setState({tags});
        this.props.onChange(tags);
    };

    showInput = () => {
        this.setState({inputVisible: true}, () => this.input.focus());
    };

    handleInputChange = (e) => {
        this.setState({inputValue: e.target.value});
    };

    handleAuthInfo = () => {
        const {inputValue} = this.state;
        let {tags} = this.state;
        if (inputValue && tags.indexOf(inputValue) === -1) {
            tags = [...tags, inputValue];
        }
        this.setState({
            tags,
            inputVisible: false,
            inputValue: '',
        });
        this.props.onChange(tags);
    };

    saveInputRef = input => this.input = input;

    render() {
        const {tags, inputVisible, inputValue} = this.state;
        return (
            <div>
                {tags.map(tag => (
                    <SlicingTag tag={tag} color='green' key={tag} closable={true}
                                onClose={() => this.handleClose(tag)}/>
                ))}
                {inputVisible && (
                    <Input
                        ref={this.saveInputRef}
                        type="text"
                        size="small"
                        style={{width: 78}}
                        value={inputValue}
                        onChange={this.handleInputChange}
                        onBlur={this.handleAuthInfo}
                        onPressEnter={this.handleAuthInfo}
                    />
                )}
                {!inputVisible && (
                    <Tag onClick={this.showInput} style={{background: '#fff', borderStyle: 'dashed'}}>
                        <Icon type="plus"/> New
                    </Tag>
                )}
            </div>
        );
    }
}
